<!--结账-->
<template>
    <div class="main-content ">
        <el-row class="cart-header">
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <el-steps :active="2" finish-status="success" simple style="margin-top: 20px;margin-right: 1%;margin-left: 1%">
                    <el-step title="購物車"></el-step>
                    <el-step title="結帳"></el-step>
                    <el-step title="訂單完成"></el-step>
                </el-steps>
            </el-col>
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
        </el-row>

        <!-- p-->
        <el-row class="hidden-sm-and-down">
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
            <el-col :lg="6" :md="6" :sm="24" :xs="24">
                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover" body-style="{ padding-top: '0px' }">
                    <div slot="header" class="clearfix">
                        <h3 style="margin: 0px;">收货資訊</h3>
                    </div>

                    <el-row class="rcv-address-item">
                        姓名: {{rcvInfo.rcvName}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        電話: {{rcvInfo.rcvPhone}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        取貨門市: {{rcvInfo.storeName}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        地址: {{rcvInfo.storeAddress}}
                    </el-row>

                </el-card>
                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover" body-style="{ padding-top: '0px' }">
                    <div slot="header" class="clearfix">
                        <h3 style="margin: 0px;">付款方式</h3>
                    </div>
                    <el-row class="rcv-address-item">
                        數支富: {{rcvInfo.payChannel | filter_payChannel}}
                    </el-row>

                </el-card>
            </el-col>
            <el-col :lg="12" :md="12" :sm="24" :xs="24">
                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover">
                    <el-row>
                        <el-col :lg="24" :xs="24"><h3>您的訂單</h3>
                        </el-col>
                    </el-row>

                    <el-row style="font-weight: bold;color: #909399;margin-bottom: 10px;">
                        <el-col :lg="18" :xs="18">商品</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right">小計</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <div>
                        <el-row v-for="(item, index) in cartList" :key="index" class="cart-item">
                            <el-col :lg="18" :xs="18">
                                <div style="display: flex;justify-content: start;align-items: center">
                                    <span style="padding-right: 10px;">{{index+1}}.</span>{{item.storeName}} <span style="font-weight: bold;">x {{item.cartNum}}</span>
                                </div>
                            </el-col>
                            <el-col :lg="6" :xs="6" style="text-align: right">
                                <span class="price">{{item.cartNum*item.price |filter_addPricePrefix}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <el-row class="cart-total-item">
                        <el-col :lg="18" :xs="18">小計</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right"><span class="total-amt">{{totalAmt |filter_addPricePrefix}}</span></el-col>
                    </el-row>
                    <el-row class="cart-total-item">
                        <el-col :lg="18" :xs="18">運费</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right">
                            <span class="total-amt">{{freightFee |filter_addPricePrefix}}</span>
                        </el-col>
                    </el-row>

                    <el-row class="cart-total-item cart-total">
                        <el-col :lg="18" :xs="18">總計</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right"><span class="total-amt">{{totalSumAmt |filter_addPricePrefix}}</span></el-col>
                    </el-row>

                    <el-row type="flex" justify="center"  style="margin: 10px auto;">
                        <el-button type="warning" @click="go2Cart">回購物車</el-button>
                        <el-button type="danger" :loading="loadingBtn" @click="checkOut">下單購買</el-button>
                    </el-row>
                </el-card>
            </el-col>

            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
        </el-row>
        <!--p over-->
        <!--m-->
        <el-row class="hidden-md-and-up">
            <el-col :sm="24" :xs="24">
                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover" body-style="{ padding-top: '0px' }">
                    <div slot="header" class="clearfix">
                        <h3 style="margin: 0px;">收货資訊</h3>
                    </div>
                    <el-row class="rcv-address-item">
                        姓名: {{rcvInfo.rcvName}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        電話: {{rcvInfo.rcvPhone}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        取貨門市: {{rcvInfo.storeName}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        地址: {{rcvInfo.storeAddress}}
                    </el-row>

                </el-card>
                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover" body-style="{ padding-top: '0px' }">
                    <div slot="header" class="clearfix">
                        <h3 style="margin: 0px;">付款方式</h3>
                    </div>
                    <el-row class="rcv-address-item">
                        數支富: {{rcvInfo.payChannel | filter_payChannel}}
                    </el-row>
                </el-card>
            </el-col>
            <el-col :sm="24" :xs="24">
                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover">
                    <el-row>
                        <el-col :lg="24" :xs="24"><h3>您的訂單</h3>
                        </el-col>
                    </el-row>

                    <el-row style="font-weight: bold;color: #909399;margin-bottom: 10px;">
                        <el-col :lg="18" :xs="18">商品</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right">小計</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <div>
                        <el-row v-for="(item, index) in cartList" :key="index" class="cart-item">
                            <el-col :lg="18" :xs="18">
                                <div style="display: flex;justify-content: start;align-items: center">
                                    <span style="padding-right: 10px;">{{index+1}}.</span>{{item.storeName}} <span style="font-weight: bold;">x {{item.cartNum}}</span>
                                </div>
                            </el-col>
                            <el-col :lg="6" :xs="6" style="text-align: right">
                                <span class="price">{{item.cartNum*item.price |filter_addPricePrefix}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <el-row class="cart-total-item">
                        <el-col :lg="18" :xs="18">小計</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right"><span class="total-amt">{{totalAmt |filter_addPricePrefix}}</span></el-col>
                    </el-row>
                    <el-row class="cart-total-item">
                        <el-col :lg="18" :xs="18">運费</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right">
                            <span class="total-amt">{{freightFee |filter_addPricePrefix}}</span>
                        </el-col>
                    </el-row>

                    <el-row class="cart-total-item cart-total">
                        <el-col :lg="18" :xs="18">總計</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right"><span class="total-amt">{{totalSumAmt |filter_addPricePrefix}}</span></el-col>
                    </el-row>

                    <el-row type="flex" justify="center" style="margin: 10px auto;">
                        <el-button type="warning" @click="go2Cart">回購物車</el-button>
                        <el-button type="danger" :loading="loadingBtn" @click="checkOut">下單購買</el-button>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!--m over-->
    </div>
</template>

<script>
    import {GetCartList,OrderCreate,OrderPayment,GetAddressById} from '@/request/api';
    // import {mapMutations} from 'vuex';
    export default {
        name: 'CheckOut',
        components: {},
        data() {
            return {
                cartList: [],
                totalAmt: 0,
                totalQty: 0,
                totalSumAmt: 0,
                freightFee: 0,
                addressList: [],//地址簿
                rcvAddress: this.$store.state.rcvAddress,//当前收货地址
                remark:'',//备注
                loadingBtn:false,
                rcvInfo: {
                    storeNo: null,
                    storeName: null,
                    storeAddress: null,
                    rcvName: null,
                    rcvPhone: null,
                    payChannel: null,//ATM 付款方式 CVS超商
                    shippingType: null,
                    cnno: null,
                }

            }
        },
        methods: {
            go2Cart() {
                this.$router.push('/cart');
            },
            checkOut() {
                let _this=this;
                _this.loadingBtn=true;
                OrderCreate({
                    preOrderNo: "SO",
                    payType: 'szfu',
                    //addressId:_this.rcvAddress.id,
                    payChannel: _this.rcvInfo.payChannel,
                    shippingType: 3,//3超取 4 货到付款
                    storeNo: _this.rcvInfo.storeNo,
                    storeName: _this.rcvInfo.storeName,
                    storeAddress: _this.rcvInfo.storeAddress,
                    rcvName: _this.rcvInfo.rcvName,
                    rcvPhone: _this.rcvInfo.rcvPhone,
                    cnno: _this.rcvInfo.cnno,
                    remark:_this.remark,
                    isValid: true,
                    useIntegral: false
                }).then(res => {
                    if (res.code == 200) {
                        this.$store.commit('SET_CARTNUM', 0);
                        OrderPayment({
                                orderNo: res.data.orderNo,
                                payType: 'szfu',
                                payChannel: res.data.payTypeCode,
                            }).then(res => {
                                _this.loadingBtn = false;
                                if (res.code == 200) {
                                    _this.$message.success('訂單產生成功，請儘快完成支付，我們會馬上備貨給您！');
                                    setTimeout(() => {
                                        _this.$router.push('/checkOutRcv?tx='+res.data.orderNo);
                                    }, 1200);
                                } else {
                                    this.$message.error(res.message);
                                    this.$router.push('/user-center/orders');
                                }
                            }).catch(err => {
                                _this.loadingBtn = false;
                                console.log(err);
                                this.$message.error('網路連線失敗，稍候請重試!');
                            });
                    } else {
                        _this.loadingBtn = false;
                        this.$message.error(res.message);
                        //this.$router.push('/user-center/orders');
                    }
                }).catch(err => {
                    _this.loadingBtn = false;
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            getCartList() {
                /*const loading = this.$loading({
                    lock: true,
                    text: "加載中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.5)"
                });*/

                GetCartList({limit: this.limit, page: this.page, isValid: true}).then(res => {
                    // loading.close();
                    this.loading = false;
                    if (res.code == 401) {
                        this.$router.push('/login');
                        return;
                    }
                    if (res.code == 200) {
                        this.cartList = res.data.cartInfoList;
                        this.totalAmt = res.data.totalAmt;
                        this.totalQty = res.data.totalQty;
                        this.totalSumAmt = res.data.totalSumAmt;
                        this.freightFee = res.data.freightFee;
                        this.$store.commit('SET_CARTNUM', res.data.totalQty);
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    // loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            getAddressById(id) {
                GetAddressById(id).then(res => {
                    if (res.code == 200) {
                        this.rcvAddress = res.data;
                        this.$store.commit('SET_RCVADDRESS', this.rcvAddress);
                        if (this.rcvAddress == null) {
                            this.$message.error('請選擇收貨地址！');
                            setTimeout(() => {
                                this.$router.push('/cart');
                            }, 1500);
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    // loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            }
        },
        mounted() {
            this.getCartList();
        },
        created() {
            if (this.$route.params.req) {
                this.rcvInfo = this.$route.params.req;
            }else{
                this.$message.error('您還沒有選取取貨門店哦,請選擇取貨門店！');
                setTimeout(() => {
                    this.$router.push('/cart');
                }, 1500);
            }
        }
    }
</script>

<style lang="less" scoped>
    .main-content {
        .cart-header {
            margin-bottom: 20px;
            text-align: center;
            background-color: black;
            color: #FFFFFF;
            padding-bottom: 20px;
        }
    }

    .cart-item {
        border-bottom-style: inset;
        border-bottom-width: 1px;
        margin: 10px auto;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-size: 14px;

        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }

    .cart-item-mobile {
        align-items: start;
        margin: 15px auto;

        .el-row {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            padding-bottom: 3px;
            border-bottom-style: inset;
            border-bottom-width: 1px;

            &:last-child {
                border-bottom-width: 0px;
                margin-bottom: 5px;
            }
        }
    }

    .cart-total-card {
        font-size: 14px;

        .cart-total-item {
            border-bottom-style: inset;
            border-bottom-width: 1px;
            margin: 20px 1%;
            padding-bottom: 10px;

            :last-child {
                text-align: right;

                span {
                    font-weight: bold;
                }
            }
        }

        .cart-total {
            border: none !important;
            font-weight: bold;
        }
    }

    .price {
        color: #F56C6C;

    }

    .total-amt {
        color: #a73535;
        font-weight: bold;
    }

    .rcv-address-item {
        border-bottom-style: inset;
        border-bottom-width: 1px;
        margin: 10px 1%;
        padding-bottom: 10px;
        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }
    .el-card__header{
        padding: 5px 20px;
    }
    /deep/.el-card__body{
        padding: 0px 20px;
    }
</style>
